import { defineComponent } from 'vue'
import './index.scss'
import { NuxtLink } from '#components'
export default defineComponent({
  name: 'GfrGridItem',
  props: {
    url: String
  },
  setup(props, { slots }) {
    // 替换url中的{lang}占位符
    const route = useRoute()
    const formatUrl = (url: string) => url.replace('{lang}', route.params.lang as string)
    const isExternal = (url: string) => /^(https?:|mailto:|tel:)/.test(url)
    return () =>
      props.url ? (
        <NuxtLink class="gfr-card" to={formatUrl(props.url)} target={isExternal(props.url) ? '_blank' : '_self'}>
          {slots.default?.()}
        </NuxtLink>
      ) : (
        <div class="gfr-card">{slots.default?.()}</div>
      )
  }
})
